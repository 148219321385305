//常规-下载文件
function LocalDownloadFileEdit(fileurl,name){
  let url = fileurl.replace("~", "")
  fetch(url, { responseType: 'blob' }).then(res => res.blob()).then(blob => {
      // 将链接地址字符内容转变成blob地址
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      // link.download = url.split('/').pop()
      link.setAttribute("download", name);
      link.click()
  })

}
//获取文件路径
function GetFileHref (Href, extension) {

  if (extension == "xlsx" || extension == "xls"
      || extension == "docx" || extension == "doc"
      || extension == "pptx" || extension == "ppt") {
      let FileHref = Href.replace("~", "");
      //FileHref = FileHref.replace("." + extension, ".pdf");

      return FileHref;
  }
  else {
      return Href.replace("~", "");
  }
}


function getURL(url) {
  var xmlhttp = new ActiveXObject( "Microsoft.XMLHTTP");
  xmlhttp.open("GET", url, false);
  xmlhttp.send();
  if(xmlhttp.readyState==4) {
      if(xmlhttp.Status != 200) alert("不存在");
      return xmlhttp.Status==200;
  }
  return false;
}

export default {
  LocalDownloadFileEdit,
  GetFileHref,
  getURL
}